<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M15.037 6.85999H5.96035C3.96535 6.85999 2.33203 8.4933 2.33203 10.4883V23.7417C2.33203 25.4333 3.54536 26.1566 5.02703 25.3283L9.61202 22.7733C10.102 22.505 10.8954 22.505 11.3737 22.7733L15.9587 25.3283C17.4404 26.1566 18.6537 25.4333 18.6537 23.7417V10.4883C18.6654 8.4933 17.032 6.85999 15.037 6.85999Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M25.6654 5.96169V19.215C25.6654 20.9067 24.452 21.6184 22.9704 20.8017L18.6654 18.3984V10.4884C18.6654 8.49336 17.032 6.86005 15.037 6.86005H9.33203V5.96169C9.33203 3.96669 10.9653 2.33337 12.9603 2.33337H22.037C24.032 2.33337 25.6654 3.96669 25.6654 5.96169Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>
