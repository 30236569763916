<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M24.0573 9.85829C22.8323 4.46829 18.1306 2.04163 14.0006 2.04163C14.0006 2.04163 14.0006 2.04163 13.9889 2.04163C9.8706 2.04163 5.15727 4.45663 3.93227 9.84663C2.56727 15.8666 6.25393 20.965 9.5906 24.1733C10.8273 25.3633 12.4139 25.9583 14.0006 25.9583C15.5873 25.9583 17.1739 25.3633 18.3989 24.1733C21.7356 20.965 25.4223 15.8783 24.0573 9.85829Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M13.9992 15.7034C16.0289 15.7034 17.6742 14.058 17.6742 12.0284C17.6742 9.99875 16.0289 8.35339 13.9992 8.35339C11.9696 8.35339 10.3242 9.99875 10.3242 12.0284C10.3242 14.058 11.9696 15.7034 13.9992 15.7034Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>
