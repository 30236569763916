<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19.5" :fill="'var(--primary-c100)'" stroke="#D6DBE1" />
    <path opacity="0.4"
      d="M23.48 11H15.52C12.07 11 10 13.06 10 16.52V24.47C10 27.94 12.07 30 15.52 30H23.47C26.93 30 28.99 27.94 28.99 24.48V16.52C29 13.06 26.93 11 23.48 11Z"
      :fill="'var(--secondary-c700)'" />
    <path
      d="M29.02 10.9798C27.23 9.17979 25.48 9.13979 23.64 10.9798L22.51 12.0998C22.41 12.1998 22.38 12.3398 22.42 12.4698C23.12 14.9198 25.08 16.8798 27.53 17.5798C27.56 17.5898 27.61 17.5898 27.64 17.5898C27.74 17.5898 27.84 17.5498 27.91 17.4798L29.02 16.3598C29.93 15.4498 30.38 14.5798 30.38 13.6898C30.38 12.7898 29.93 11.8998 29.02 10.9798Z"
      :fill="'var(--secondary-c700)'" />
    <path
      d="M25.8601 18.4198C25.5901 18.2898 25.3301 18.1598 25.0901 18.0098C24.8901 17.8898 24.6901 17.7598 24.5001 17.6198C24.3401 17.5198 24.1601 17.3698 23.9801 17.2198C23.9601 17.2098 23.9001 17.1598 23.8201 17.0798C23.5101 16.8298 23.1801 16.4898 22.8701 16.1198C22.8501 16.0998 22.7901 16.0398 22.7401 15.9498C22.6401 15.8398 22.4901 15.6498 22.3601 15.4398C22.2501 15.2998 22.1201 15.0998 22.0001 14.8898C21.8501 14.6398 21.7201 14.3898 21.6001 14.1298C21.4701 13.8498 21.3701 13.5898 21.2801 13.3398L15.9001 18.7198C15.5501 19.0698 15.2101 19.7298 15.1401 20.2198L14.7101 23.1998C14.6201 23.8298 14.7901 24.4198 15.1801 24.8098C15.5101 25.1398 15.9601 25.3098 16.4601 25.3098C16.5701 25.3098 16.6801 25.2998 16.7901 25.2898L19.7601 24.8698C20.2501 24.7998 20.9101 24.4698 21.2601 24.1098L26.6401 18.7298C26.3901 18.6498 26.1401 18.5398 25.8601 18.4198Z"
      :fill="'var(--secondary-c700)'" />
  </svg>
</template>