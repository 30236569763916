<template>
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="4" fill="url(#paint0_linear_1214_666)" />
    <path
      d="M15.668 8C13.048 8 10.918 10.13 10.918 12.75C10.918 15.32 12.928 17.4 15.548 17.49C15.628 17.48 15.708 17.48 15.768 17.49C15.788 17.49 15.798 17.49 15.818 17.49C15.828 17.49 15.828 17.49 15.838 17.49C18.398 17.4 20.408 15.32 20.418 12.75C20.418 10.13 18.288 8 15.668 8Z"
      :fill="'white'" fill-opacity="1" />
    <path
      d="M20.7489 20.1499C17.9589 18.2899 13.4089 18.2899 10.5989 20.1499C9.32891 20.9999 8.62891 22.1499 8.62891 23.3799C8.62891 24.6099 9.32891 25.7499 10.5889 26.5899C11.9889 27.5299 13.8289 27.9999 15.6689 27.9999C17.5089 27.9999 19.3489 27.5299 20.7489 26.5899C22.0089 25.7399 22.7089 24.5999 22.7089 23.3599C22.6989 22.1299 22.0089 20.9899 20.7489 20.1499Z"
      :fill="'white'" fill-opacity="1" />
    <path
      d="M26.6573 13.3401C26.8173 15.2801 25.4373 16.9801 23.5273 17.2101C23.5173 17.2101 23.5173 17.2101 23.5073 17.2101H23.4773C23.4173 17.2101 23.3573 17.2101 23.3073 17.2301C22.3373 17.2801 21.4473 16.9701 20.7773 16.4001C21.8073 15.4801 22.3973 14.1001 22.2773 12.6001C22.2073 11.7901 21.9273 11.0501 21.5073 10.4201C21.8873 10.2301 22.3273 10.1101 22.7773 10.0701C24.7373 9.9001 26.4873 11.3601 26.6573 13.3401Z"
      :fill="'white'" fill-opacity="1" />
    <path
      d="M28.6562 22.5899C28.5763 23.5599 27.9563 24.3999 26.9163 24.9699C25.9163 25.5199 24.6562 25.7799 23.4062 25.7499C24.1263 25.0999 24.5462 24.2899 24.6262 23.4299C24.7262 22.1899 24.1363 20.9999 22.9563 20.0499C22.2863 19.5199 21.5063 19.0999 20.6562 18.7899C22.8663 18.1499 25.6463 18.5799 27.3563 19.9599C28.2763 20.6999 28.7463 21.6299 28.6562 22.5899Z"
      :fill="'white'" fill-opacity="1" />
    <defs>
      <linearGradient id="paint0_linear_1214_666" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
        <stop :stop-color="'var(--secondary-c600)'" />
        <stop offset="1" :stop-color="'var(--secondary-c700)'" />
      </linearGradient>
    </defs>
  </svg>
</template>