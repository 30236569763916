<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19.5" :fill="'var(--primary-c100)'" stroke="#D6DBE1"/>
    <g opacity="0.4">
      <path
        d="M14.9925 18.4136C15.1589 17.8889 15.4049 17.3998 15.7171 16.9608L23.0384 24.2814C22.1794 24.8913 21.1312 25.2499 19.9987 25.2499C17.1003 25.2499 14.7487 22.8994 14.7487 19.9999C14.7487 19.4469 14.8353 18.914 14.9925 18.4136Z"
        :fill="'var(--secondary-c700)'" />
      <path
        d="M16.9544 15.7226L24.2757 23.0448C24.8887 22.186 25.2487 21.135 25.2487 19.9999C25.2487 17.1005 22.8971 14.7499 19.9987 14.7499C18.8639 14.7499 17.8135 15.1102 16.9544 15.7226Z"
        :fill="'var(--secondary-c700)'" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9987 31.6666C26.4427 31.6666 31.6654 26.4432 31.6654 19.9999C31.6654 13.5566 26.4427 8.33325 19.9987 8.33325C13.5547 8.33325 8.33203 13.5566 8.33203 19.9999C8.33203 26.4432 13.5547 31.6666 19.9987 31.6666ZM26.9987 19.9999C26.9987 23.8659 23.8656 26.9999 19.9987 26.9999C16.1318 26.9999 12.9987 23.8659 12.9987 19.9999C12.9987 16.1339 16.1318 12.9999 19.9987 12.9999C23.8656 12.9999 26.9987 16.1339 26.9987 19.9999Z"
        :fill="'var(--secondary-c700)'" />
    </g>
    <path 
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 27C23.8669 27 27 23.866 27 20C27 16.134 23.8669 13 20 13C16.1331 13 13 16.134 13 20C13 23.866 16.1331 27 20 27ZM20 14.75C18.8652 14.75 17.8148 15.1103 16.9557 15.7227L24.277 23.0448C24.89 22.1861 25.25 21.135 25.25 20C25.25 17.1006 22.8984 14.75 20 14.75ZM14.9938 18.4136C15.1602 17.889 15.4062 17.3999 15.7184 16.9609L23.0397 24.2814C22.1807 24.8914 21.1325 25.25 20 25.25C17.1016 25.25 14.75 22.8994 14.75 20C14.75 19.447 14.8366 18.9141 14.9938 18.4136Z"
      :fill="'var(--secondary-c700)'" />
  </svg>
</template>