<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19.5" :fill="'var(--primary-c100)'" stroke="#D6DBE1"/>
    <path
      opacity="0.4"
      d="M20 32C26.6274 32 32 26.6274 32 20C32 13.3726 26.6274 8 20 8C13.3726 8 8 13.3726 8 20C8 26.6274 13.3726 32 20 32Z"
      :fill="'var(--secondary-c700)'" />
    <path
      d="M18.2969 24.2956C18.0569 24.2956 17.8289 24.1996 17.6609 24.0316L14.2649 20.6356C13.9169 20.2876 13.9169 19.7116 14.2649 19.3636C14.6129 19.0156 15.1889 19.0156 15.5369 19.3636L18.2969 22.1236L24.4649 15.9556C24.8129 15.6076 25.3889 15.6076 25.7369 15.9556C26.0849 16.3036 26.0849 16.8796 25.7369 17.2276L18.9329 24.0316C18.7649 24.1996 18.5369 24.2956 18.2969 24.2956Z"
      :fill="'var(--secondary-c700)'" />
  </svg>
</template>