<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.4526 20.8134L22.551 21.455C22.026 21.63 21.606 22.0384 21.431 22.575L20.7893 24.4767C20.241 26.1217 17.931 26.0867 17.4176 24.4417L15.2593 17.5C14.8393 16.1234 16.111 14.84 17.476 15.2717L24.4293 17.43C26.0626 17.9434 26.086 20.265 24.4526 20.8134Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      opacity="0.4"
      d="M13.4173 24.5C19.5385 24.5 24.5007 19.5379 24.5007 13.4167C24.5007 7.29555 19.5385 2.33337 13.4173 2.33337C7.29616 2.33337 2.33398 7.29555 2.33398 13.4167C2.33398 19.5379 7.29616 24.5 13.4173 24.5Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>
