import { CreatePreferenceRequest } from "@/core/model/Preference";
import { PreferenceStore } from "@/store";

export const testColors = (gradientColors) => {
    const keys = Object.keys(gradientColors).sort((a, b) => {
        const numA = parseInt(a.slice(11));
        const numB = parseInt(b.slice(11));
        return numA - numB;
    });

    const colorsHex: string[] = [];

    keys.forEach(key => {
        const value = gradientColors[key];
        document.documentElement.style.setProperty(key, value);
        colorsHex.push(value);
    });
};

export const testColorsText = (selectedColorTitle, selectedColorSubtitle) => {
    const titleColor = selectedColorTitle;
    document.documentElement.style.setProperty('--text-c1000', titleColor);
    const subTitle = selectedColorSubtitle;
    document.documentElement.style.setProperty('--text-c600', subTitle);
};

export const updateColors = async (preferences: CreatePreferenceRequest) => {
    await PreferenceStore.actions.GetAll();
    localStorage.setItem('logo', JSON.stringify(PreferenceStore.state.preferences!.logo));
    localStorage.setItem('colorTitle', JSON.stringify(PreferenceStore.state.preferences!.colorTitle));
    localStorage.setItem('colorSubtitle', JSON.stringify(PreferenceStore.state.preferences!.colorSubtitle));

    const colorTitle = PreferenceStore.state.preferences!.colorTitle;
    document.documentElement.style.setProperty('--text-c1000', colorTitle);
    const colorSubtitle = PreferenceStore.state.preferences!.colorSubtitle;
     document.documentElement.style.setProperty('--text-c600', colorSubtitle);

    const secondaryKeys = Object.keys(preferences).filter(key => key.startsWith("secondary_c")).sort((a, b) => {
        const numA = parseInt(a.slice(11));
        const numB = parseInt(b.slice(11));
        return numA - numB;
    });

    const secondaryColorsHex: string[] = [];
    let secondaryColor = '';

    secondaryKeys.forEach(key => {
        const value = preferences[key];
        const propertyName = `--${key.replace('_', '-').toLowerCase()}`;
        document.documentElement.style.setProperty(propertyName, value);
        secondaryColorsHex.push(value);
        if (propertyName === '--secondary-c600') {
            secondaryColor = value;
        }
    });

    localStorage.setItem('secondaryColor', JSON.stringify(secondaryColor));
    localStorage.setItem('secondaryColors', JSON.stringify(secondaryColorsHex));


    const primaryKeys = Object.keys(preferences).filter(key => key.startsWith("primary_c")).sort((a, b) => {
        const numA = parseInt(a.slice(9));
        const numB = parseInt(b.slice(9));
        return numA - numB;
    });

    const primaryColorsHex: string[] = [];
    let primaryColor = '';

    primaryKeys.forEach(key => {
        const value = preferences[key];
        const propertyName = `--${key.replace('_', '-').toLowerCase()}`;
        document.documentElement.style.setProperty(propertyName, value);
        primaryColorsHex.push(value);
        if (propertyName === '--primary-c600') {
            primaryColor = value;
        }
    });

    localStorage.setItem('primaryColor', JSON.stringify(primaryColor));
    localStorage.setItem('primaryColors', JSON.stringify(primaryColorsHex));
};


export const getColors = () => {
    const searchPrimaryColors = localStorage.getItem('primaryColors');
    if (searchPrimaryColors !== null) {
        const setPrimaryColors = JSON.parse(searchPrimaryColors);

        setPrimaryColors.forEach((color, index) => {
            const variableName = `--primary-c${index + 1}00`;
            document.documentElement.style.setProperty(variableName, color);
        });
    }

    const searchSecondaryColors = localStorage.getItem('secondaryColors');
    if (searchSecondaryColors !== null) {
        const setSecondaryColors = JSON.parse(searchSecondaryColors);

        setSecondaryColors.forEach((color, index) => {
            const variableName = `--secondary-c${index + 1}00`;
            document.documentElement.style.setProperty(variableName, color);
        });
    }

    const searchTitleColor = localStorage.getItem('colorTitle');
    if (searchTitleColor !== null) {
        document.documentElement.style.setProperty('--text-c1000', JSON.parse(searchTitleColor));
    }

    const searchSubtitleColor = localStorage.getItem('colorSubtitle');
    if (searchSubtitleColor !== null) {
         document.documentElement.style.setProperty('--text-c600', JSON.parse(searchSubtitleColor));
    }
}