<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.875 9.3335V11.7835C21.3617 11.7135 20.7783 11.6785 20.125 11.6668V9.3335C20.125 5.6585 19.0867 3.2085 14 3.2085C8.91333 3.2085 7.875 5.6585 7.875 9.3335V11.6668C7.22167 11.6785 6.63833 11.7135 6.125 11.7835V9.3335C6.125 5.95016 6.94167 1.4585 14 1.4585C21.0583 1.4585 21.875 5.95016 21.875 9.3335Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      opacity="0.4"
      d="M25.6654 17.4998V19.8332C25.6654 24.4998 24.4987 25.6665 19.832 25.6665H8.16536C3.4987 25.6665 2.33203 24.4998 2.33203 19.8332V17.4998C2.33203 13.6032 3.1487 12.1448 6.1237 11.7832C6.63703 11.7132 7.22036 11.6782 7.8737 11.6665H20.1237C20.777 11.6782 21.3604 11.7132 21.8737 11.7832C24.8487 12.1448 25.6654 13.6032 25.6654 17.4998Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M9.33464 19.8335C9.18297 19.8335 9.0313 19.7985 8.8913 19.7402C8.73963 19.6818 8.62298 19.6002 8.50631 19.4952C8.29631 19.2735 8.16797 18.9702 8.16797 18.6668C8.16797 18.5152 8.20295 18.3635 8.26129 18.2235C8.31962 18.0835 8.40131 17.9552 8.50631 17.8385C8.62298 17.7335 8.73963 17.6518 8.8913 17.5935C9.32296 17.4068 9.83629 17.5118 10.163 17.8385C10.268 17.9552 10.3497 18.0835 10.408 18.2235C10.4663 18.3635 10.5013 18.5152 10.5013 18.6668C10.5013 18.9702 10.373 19.2735 10.163 19.4952C9.94129 19.7052 9.63797 19.8335 9.33464 19.8335Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M13.9987 19.8334C13.6837 19.8334 13.392 19.7051 13.1704 19.4951C13.0654 19.3784 12.9837 19.2501 12.9253 19.1101C12.867 18.9701 12.832 18.8184 12.832 18.6667C12.832 18.3517 12.9604 18.0601 13.1704 17.8384C13.602 17.4067 14.3837 17.4067 14.827 17.8384C15.037 18.0601 15.1654 18.3517 15.1654 18.6667C15.1654 18.8184 15.1304 18.9701 15.072 19.1101C15.0137 19.2501 14.932 19.3784 14.827 19.4951C14.6054 19.7051 14.302 19.8334 13.9987 19.8334Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M18.6667 19.8334C18.3633 19.8334 18.06 19.7051 17.8383 19.4951C17.6283 19.2734 17.5 18.9817 17.5 18.6667C17.5 18.3517 17.6283 18.0601 17.8383 17.8384C18.2817 17.4067 19.0633 17.4067 19.495 17.8384C19.5417 17.8967 19.5883 17.9551 19.635 18.0251C19.6817 18.0834 19.7167 18.1534 19.74 18.2234C19.775 18.2934 19.7983 18.3634 19.81 18.4334C19.8217 18.5151 19.8333 18.5967 19.8333 18.6667C19.8333 18.9701 19.705 19.2734 19.495 19.4951C19.2733 19.7051 18.97 19.8334 18.6667 19.8334Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>