<template>
  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="43" height="43" rx="8" fill="white" fill-opacity="0.1" />
    <rect x="0.5" y="0.5" width="42" height="42" rx="7.5" stroke="white" stroke-opacity="0.15" />
    <path
      d="M26.9883 16.505C26.6395 12.455 24.5583 10.8013 20.002 10.8013H19.8558C14.827 10.8013 12.8133 12.815 12.8133 17.8438V25.1788C12.8133 30.2075 14.827 32.2213 19.8558 32.2213H20.002C24.5245 32.2213 26.6058 30.59 26.977 26.6075"
      stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M20.1233 21.5H32.9258" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M30.4188 17.7312L34.1875 21.5L30.4188 25.2687" stroke="white" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>