<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.25 17.5C1.25 17.91 1.59 18.25 2 18.25H9V18.5C9 20 9.9 20.5 11 20.5H17C18.1 20.5 19 20 19 18.5V18.25H22C22.41 18.25 22.75 17.91 22.75 17.5C22.75 17.09 22.41 16.75 22 16.75H19V16.5C19 15 18.1 14.5 17 14.5H11C9.9 14.5 9 15 9 16.5V16.75H2C1.59 16.75 1.25 17.09 1.25 17.5Z"
      :fill="'var(--secondary-c800)'"
    />
    <path
      opacity="0.4"
      d="M1.25 6.5C1.25 6.91 1.59 7.25 2 7.25H5V7.5C5 9 5.9 9.5 7 9.5H13C14.1 9.5 15 9 15 7.5V7.25H22C22.41 7.25 22.75 6.91 22.75 6.5C22.75 6.09 22.41 5.75 22 5.75H15V5.5C15 4 14.1 3.5 13 3.5H7C5.9 3.5 5 4 5 5.5V5.75H2C1.59 5.75 1.25 6.09 1.25 6.5Z"
      :fill="'var(--secondary-c800)'"
    />
  </svg>
</template>