<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M22.5 10.7599C22.5 10.2899 22.12 9.8999 21.64 9.8999H3.36C2.88 9.8999 2.5 10.2799 2.5 10.7599C2.5 16.6499 6.61 20.7599 12.5 20.7599C18.39 20.7599 22.5 16.6399 22.5 10.7599Z"
      :fill="'var(--secondary-c800)'"
    />
    <path
      d="M13.0284 3.45992L15.8784 6.29992C16.1684 6.58992 16.1684 7.06992 15.8784 7.35992C15.5884 7.64992 15.1084 7.64992 14.8184 7.35992L13.2484 5.79992V15.3699C13.2484 15.7799 12.9084 16.1199 12.4984 16.1199C12.0884 16.1199 11.7484 15.7799 11.7484 15.3699V5.79992L10.1884 7.36992C9.89844 7.65992 9.41844 7.65992 9.12844 7.36992C8.97844 7.21992 8.89844 7.02992 8.89844 6.83992C8.89844 6.64992 8.96844 6.45992 9.11844 6.30992L11.9684 3.46992C12.2584 3.16992 12.7384 3.16992 13.0284 3.45992Z"
      :fill="'var(--secondary-c800)'"
    />
  </svg>
</template>