import { Filters, OptionSelect, PaginateRequest, PaginateResponse } from '@/core/model';
import { Activity } from '@/core/model/Activity';
import { getPaginate, getByCategory } from '@/core/service/ActivityService';
import { reactive } from 'vue';

const state = reactive<{ activities?: Activity[]; listActivitiesSelect: OptionSelect[] }>({
  activities: undefined,
  listActivitiesSelect: [],
});

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<Activity> {
  if (!state.activities) return { data: [], page: 0, recordsTotal: 0 };
  let data = state.activities;
  if (f?.name) data = data.filter((d) => d.name.toLowerCase().includes(f.name ?? ''));
  if (f?.category) data = data.filter((d) => d.category.id === f?.category);
  const recordsTotal = data.length;

  const start = (page - 1) * limit;
  const end = start + limit;
  data = data.slice(start, end);
  return { data, page, recordsTotal };
}

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.activities || forceRefresh) {
      const { data } = await getPaginate();
      state.activities = data;
      state.listActivitiesSelect = data.map((d) => ({ text: d.name, value: d.id }));
    }
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Activity>> {
    await this.GetAll();
    return applyFilters(paginate, filters);
  },
  async GetByCategory(categoryId: string) {
    const data = await getByCategory(categoryId);
    state.activities = data;
    state.listActivitiesSelect = data.map((d) => ({ text: d.name, value: d.id }));
  }
};

const getters = {
  async GetByCategory(categoryId: string) {
  if (state.activities) {
    const activities = state.activities.filter((activity) => activity.category.id === categoryId);
    state.listActivitiesSelect = activities.map((d) => ({ text: d.name, value: d.id }));
  }
}};

export { state, actions, getters };
