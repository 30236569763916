<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M25.6654 9.11159V16.2166L23.7637 14.5833C22.8537 13.8016 21.3837 13.8016 20.4737 14.5833L15.6204 18.7483C14.7104 19.5299 13.2404 19.5299 12.3304 18.7483L11.9337 18.4216C11.1054 17.6983 9.78703 17.6283 8.8537 18.2583L3.1137 22.1083L2.98536 22.2016C2.5537 21.2683 2.33203 20.1599 2.33203 18.8883V9.11159C2.33203 4.86492 4.8637 2.33325 9.11036 2.33325H18.887C23.1337 2.33325 25.6654 4.86492 25.6654 9.11159Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M10.4993 12.11C12.0328 12.11 13.276 10.8668 13.276 9.33331C13.276 7.7998 12.0328 6.55664 10.4993 6.55664C8.96581 6.55664 7.72266 7.7998 7.72266 9.33331C7.72266 10.8668 8.96581 12.11 10.4993 12.11Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M25.6683 16.2167V18.8883C25.6683 23.135 23.1366 25.6667 18.8899 25.6667H9.11328C6.13828 25.6667 3.99161 24.4183 2.98828 22.2017L3.11661 22.1083L8.85661 18.2583C9.78995 17.6283 11.1083 17.6983 11.9366 18.4217L12.3333 18.7483C13.2433 19.53 14.7133 19.53 15.6233 18.7483L20.4766 14.5833C21.3866 13.8017 22.8566 13.8017 23.7666 14.5833L25.6683 16.2167Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>