<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M24.5929 10.01V17.9899C24.5929 19.2966 23.8929 20.51 22.7612 21.175L15.8312 25.1766C14.6995 25.83 13.2995 25.83 12.1562 25.1766L5.2262 21.175C4.09453 20.5216 3.39453 19.3083 3.39453 17.9899V10.01C3.39453 8.70329 4.09453 7.48991 5.2262 6.82491L12.1562 2.82325C13.2879 2.16992 14.6879 2.16992 15.8312 2.82325L22.7612 6.82491C23.8929 7.48991 24.5929 8.69163 24.5929 10.01Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M13.9996 14.0001C15.5009 14.0001 16.7179 12.7831 16.7179 11.2818C16.7179 9.78046 15.5009 8.56348 13.9996 8.56348C12.4983 8.56348 11.2812 9.78046 11.2812 11.2818C11.2812 12.7831 12.4983 14.0001 13.9996 14.0001Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M17.1247 19.4368C18.0697 19.4368 18.6181 18.3869 18.0931 17.6052C17.2997 16.4269 15.7597 15.6335 13.9981 15.6335C12.2364 15.6335 10.6964 16.4269 9.90307 17.6052C9.37807 18.3869 9.92641 19.4368 10.8714 19.4368H17.1247Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>
