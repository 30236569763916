<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M24.5929 10.0101V17.99C24.5929 19.2967 23.8929 20.5101 22.7612 21.1751L15.8312 25.1767C14.6996 25.8301 13.2995 25.8301 12.1562 25.1767L5.22621 21.1751C4.09454 20.5218 3.39453 19.3084 3.39453 17.99V10.0101C3.39453 8.70341 4.09454 7.49003 5.22621 6.82503L12.1562 2.82337C13.2879 2.17004 14.6879 2.17004 15.8312 2.82337L22.7612 6.82503C23.8929 7.49003 24.5929 8.69175 24.5929 10.0101Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M14 16.0416C13.5217 16.0416 13.125 15.645 13.125 15.1666V9.04163C13.125 8.56329 13.5217 8.16663 14 8.16663C14.4783 8.16663 14.875 8.56329 14.875 9.04163V15.1666C14.875 15.645 14.4783 16.0416 14 16.0416Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M13.9987 20.125C13.847 20.125 13.6954 20.09 13.5554 20.0316C13.4037 19.9733 13.287 19.8917 13.1703 19.7867C13.0653 19.67 12.9837 19.5417 12.9137 19.4017C12.8554 19.2617 12.832 19.11 12.832 18.9583C12.832 18.655 12.9487 18.3516 13.1703 18.13C13.287 18.025 13.4037 17.9434 13.5554 17.885C13.987 17.6984 14.5004 17.8033 14.8271 18.13C14.9321 18.2466 15.0137 18.3633 15.072 18.515C15.1303 18.655 15.1654 18.8067 15.1654 18.9583C15.1654 19.11 15.1303 19.2617 15.072 19.4017C15.0137 19.5417 14.9321 19.67 14.8271 19.7867C14.6054 20.0084 14.3137 20.125 13.9987 20.125Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>