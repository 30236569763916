// TOKEN
export const getLocalToken = () => localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : undefined;
export const deleteLocalToken = () => localStorage.removeItem("token");
export const setLocalToken = token => localStorage.setItem("token", JSON.stringify(token));

// USER
export const getLocalUsuario = () => JSON.parse(localStorage.getItem("usuario"));
export const deleteLocalUsuario = () => localStorage.removeItem("usuario");
export const setLocalUsuario = usuario => localStorage.setItem("usuario", JSON.stringify(usuario));


// COLABORADOR
export const getLocalColab = () => JSON.parse(localStorage.getItem("colaborador"));
export const deleteLocalColab = () => localStorage.removeItem("colaborador");
export const setLocalColab = usuario => localStorage.setItem("colaborador", JSON.stringify(usuario));