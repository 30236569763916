import { OptionSelect } from '@/core/model';
import { Preference } from '@/core/model/Preference';
import { getPreferences } from '@/core/service/PreferenceService';
import { reactive } from 'vue';

const state = reactive<{ preferences?: Preference; listPreferencesSelect: OptionSelect[] }>({
    preferences: undefined,
    listPreferencesSelect: [],
});

const actions = {
    async GetAll(forceRefresh = false) {
        if (!state.preferences || forceRefresh) {
            const data = await getPreferences();
            state.preferences = data[0];
            state.listPreferencesSelect = data.map((d) => ({ text: d.primary.c600, value: d.primary.c600}));
        }
    },
};

export { state, actions };
