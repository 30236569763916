// Styles
import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css'
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';
import { aliases, mdi } from "vuetify/lib/iconsets/mdi.mjs";

const computedStyles = getComputedStyle(document.documentElement);
const primaryC800 = computedStyles.getPropertyValue('--primary-c800');
const primaryC900 = computedStyles.getPropertyValue('--primary-c900');

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: primaryC800,
    'primary-text-title': '45505F',
    'primary-darken-1': '#3700B3',
    secondary: primaryC900,
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    'gray-500': '#697B8E',
    warning: '#FB8C00',
    'bg-primary': "FFFFFF",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  }
});
