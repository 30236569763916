import { http } from "../api";
import { PaginateResponse } from "../model";
import { Role } from "../model/Role";

const controller = '/roles';

export async function getPaginate(): Promise<PaginateResponse<Role>> {
  const { data } = await http.get<Role[]>(controller);

  return { page: 1, recordsTotal: data.length, data };
}
