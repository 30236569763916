import { http } from "../api";
import { Filters } from "../model";
import { Bed } from "../model/Bed";

const controller = "/bed";

export type BedResponse = {
    total: number;
    page: number;
    limit: number;
    totalPages: number;
    data: Bed[];
}

export async function getPaginate() : Promise<Bed[]> {
    const { data } = await http.get<BedResponse>(`${controller}/?situacao_leito=Ativo&page=1&limit=10000`);
    return data.data;
}

export async function getByName(filters: Filters) : Promise<Bed> {
    const params: Record<string, string> = {};
    if (filters?.name) {
      params['ds_leito'] = filters?.name;
    }
    const { data } = await http.get<Bed>(controller, {
        params,
    });
    
    return data;
}

export async function getPatientInfoByBed(dsLeito: string) : Promise<any> {
    const {data} = await http.get<any>(`${controller}/search/byPatient?ds_leito=${dsLeito}`);
    return data;
}
