import { Filters, OptionSelect, OptionSelectBed, PaginateRequest, PaginateResponse } from '@/core/model';
import { Bed } from '@/core/model/Bed';
import { getPaginate, getPatientInfoByBed } from '@/core/service/BedService';
import { reactive } from 'vue';

const state = reactive<{ beds?: Bed[]; listBedsSelect: OptionSelectBed[]; listUnitsSelect: OptionSelect[] }>({
  beds: undefined,
  listBedsSelect: [],
  listUnitsSelect: [],
});

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<Bed> {
  if (!state.beds) return { data: [], page: 0, recordsTotal: 0 };
  let data = state.beds;
  if (f?.name) data = data.filter((d) => d.ds_leito.toLowerCase().includes(f.name ?? ''));

  const start = (page - 1) * limit;
  const end = start + limit;
  data = state.beds.slice(start, end);
  return { data, page, recordsTotal: state.beds.length };
}

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.beds || forceRefresh) {
      const data = await getPaginate();
      state.beds = data;
      state.listBedsSelect = data.map((d) => ({
        text: d.ds_enfermaria,
        value: d.id,
        unit: d.unidade_internacao,
        patientName: null,
        attendance: d.atendimento,
      }));

      state.listUnitsSelect = data.map((d) => ({
        text: d.unidade_internacao,
        value: d.unidade_internacao,
        bed: d.ds_enfermaria,
      })).filter((d, i, self) => i === self.findIndex((u) => u.value === d.value));

      state.listUnitsSelect.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      state.listBedsSelect.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      state.beds.sort((a, b) => {
        const unitCompare = a.unidade_internacao.toLowerCase().localeCompare(b.unidade_internacao.toLowerCase());

        if(unitCompare == 0) {
          return a.ds_enfermaria.toLowerCase().localeCompare(b.ds_enfermaria.toLowerCase());
        }

        return unitCompare;
      })
    }
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Bed>> {
    await this.GetAll();
    return applyFilters(paginate, filters);
  },
  async GetPatientInfoByBed(dsLeito: string) {
    const data = await getPatientInfoByBed(dsLeito);
    //state.beds = [data];
    state.listBedsSelect = [data].map((d) => ({
      text: d.ds_leito,
      value: d.id,
      unit: d.unidade_internacao,
      patientName: d.patient!.nm_paciente!,
      attendance: d.atendimento,
    }));
    
  },
};

const getters = {};

export { state, actions, getters };
