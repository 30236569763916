import { Filters, OptionSelectAttendance, PaginateRequest, PaginateResponse } from '@/core/model';
import { Attendance } from '@/core/model/Attendance';
import { getPaginate, getAttendanceByName } from '@/core/service/AttendanceService';
import { reactive } from 'vue';

const state = reactive<{ attendances?: Attendance[]; listAttendancesSelect: OptionSelectAttendance[] }>({
  attendances: undefined,
  listAttendancesSelect: [],
});

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<Attendance> {
  if (!state.attendances) return { data: [], page: 0, recordsTotal: 0 };
  let data = state.attendances;
  if (f?.name) data = data.filter((d) => d.nm_paciente!.toLowerCase().includes(f.name ?? ''));

  const start = (page - 1) * limit;
  const end = start + limit;
  data = state.attendances.slice(start, end);
  return { data, page, recordsTotal: state.attendances.length };
}

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.attendances || forceRefresh) {
      const { data } = await getPaginate();
      state.attendances = data;
      state.listAttendancesSelect = data.map((d) => ({ text: d.nm_paciente!, value: d.nm_paciente!, attendance: d.atendimento!, birthDate: d.dt_nascimento!, unit: d.unidade_internacao!, bed: d.ds_enfermaria! }));
      state.listAttendancesSelect.sort((a, b) => a.value.localeCompare(b.value));
    }
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Attendance>> {
    await this.GetAll();
    return applyFilters(paginate, filters);
  },
  async GetAttendanceByName(attendanceId : string) {
    const data = await getAttendanceByName(attendanceId);
    state.attendances = data;
    state.listAttendancesSelect = data.map((d) => ({ text: d.nm_paciente!, value: d.nm_paciente!, attendance: d.atendimento!, birthDate: d.dt_nascimento!, unit: d.unidade_internacao!, bed: d.ds_enfermaria! }));
  },
};

const getters = {};

export { state, actions, getters };
