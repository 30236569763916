import { ref, computed } from 'vue';
import { login } from '@/core/service/AuthService';

import * as storage from './storage';
import { Credential } from '@/core/model/Credential';
import swal from 'sweetalert2';
import { LoginResponse } from '@/core/model/LoginResponse';
import { Department } from '@/core/model/Department';
let token = localStorage.getItem('token');

if (token) token = JSON.parse(String(localStorage.getItem(token)));

const state = ref<LoginResponse>();

function ActionSetUser(payload: LoginResponse) {
  state.value = payload;
  storage.setLocalUsuario(payload);
  storage.setLocalToken(payload.token);
}

const actions = {
  login: async (credentials: Credential) => {
    try {
      const user = await login(credentials);
      ActionSetUser(user);
    } catch (error: any) {
      const message = error?.response?.data?.message;
      if (message == 'invalid_credentials')
        swal.fire({
          icon: 'warning',
          text: 'Informações de usuário ou senha incorretas',
          title: 'Atenção',
        });
      throw new Error('Informações de usuário ou senha incorretas');
    }
  },
  setUser: async (payload: any) => {
    ActionSetUser(payload);
  },
  logout: async ()  => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    storage.deleteLocalToken();
    storage.deleteLocalUsuario();
    state.value = undefined;
  },

  // forgotPwd: async (email: string) => {
  //   const data = await resetPassword(email);
  //   return data;
  // },

  ActionCheckToken() {
    try {
      const token = storage.getLocalToken();
      if (state.value?.token) {
        return Promise.resolve(state.value.token);
      } else if (!token) {
        return this.logout();
      } else {
        ActionSetUser(storage.getLocalUsuario());
        ('');
        return Promise.resolve(token);
      }
    } catch (error) {
      return actions.logout();
    }
  },
};
const getters = {
  getStatusLoggedIn: computed(() => {
    return !!(state.value && state.value.token);
  }),
  getToken: computed(() => {
    return state.value?.token;
  }),
  getUser: computed(() => {
    return state.value;
  }),
  getUserDepartments: (): Department[] => {
    return storage.getLocalUsuario().departments.map<Department>((d) => {
      return {
        id: d.id,
        name: d.name,
        company: {
          id: d.companyId,
          name: '',
        },
      };
    });
  },
};
export { getters, state, actions };
