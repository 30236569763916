import { refreshAllData } from '@/store';
import { http } from '../api';
import { OptionSelect, PaginateResponse } from '../model';
import { CreateDepartmentRequest, Department } from '../model/Department';

const controller = 'department';

export type DepartmentSearch = {
  page: number,
  limit: number,
  showDisabled?: boolean,
}

type ResponseDepartment = {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
  data: Department[];
}

const mountParams = (search: DepartmentSearch) => {
  const queryParams = Object.entries(search).map(([key, value]) => `${key}=${value}`).join('&');
  return `?${queryParams}`;
}

export async function getPaginate(search: DepartmentSearch = { page: 1, limit: 10000, showDisabled: true }): Promise<PaginateResponse<Department>> {
  const { data } = await http.get<ResponseDepartment>(`${controller}/${mountParams(search)}`);
  return { page: data.page, recordsTotal: data.total, data: data.data };
}

export async function getBy(id: string): Promise<Department> {
  const { data } = await http.get<Department>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateDepartmentRequest): Promise<Department> {
  const { data } = await http.post<Department>(`${controller}/`, payload);
  await refreshAllData();
  return data;
}

export async function update(id: string, payload: CreateDepartmentRequest): Promise<Department> {
  const { data } = await http.patch<Department>(`${controller}/${id}`, payload);
  await refreshAllData();
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  await refreshAllData();
  return status === 204;
}

export async function enable(id: string): Promise<Department> {
  const { data } = await http.post(`${controller}/enable/${id}`);
  await refreshAllData();
  return data;
}

export async function disable(id: string): Promise<Department> {
  const { data } = await http.post(`${controller}/disable/${id}`);
  await refreshAllData();
  return data;
}

export async function getSelectIconListDepartament(): Promise<OptionSelect[]> {
  const data = [
    { text: 'Dashboard', value: 'mdi-view-dashboard' },
    { text: 'Cama de Hospital', value: 'mdi-bed' },
    { text: 'Prontuário Médico', value: 'mdi-clipboard-pulse' },
    { text: 'Administração', value: 'mdi-office-building' },
    { text: 'Recursos Humanos', value: 'mdi-human-handsup' },
    { text: 'Finanças', value: 'mdi-currency-usd' },
    { text: 'Paciente', value: 'mdi-account-heart' },
    { text: 'Agendamento', value: 'mdi-calendar-clock' },
    { text: 'Laboratório', value: 'mdi-flask' },
    { text: 'Cirurgia', value: 'mdi-human-baby-changing-table' },
    { text: 'Ambulância', value: 'mdi-ambulance' },
    { text: 'Emergência', value: 'mdi-alert' },
    { text: 'Médico', value: 'mdi-stethoscope' },
    { text: 'Hospital', value: 'mdi-hospital-building' },
    { text: 'Cérebro', value: 'mdi-brain' },
    { text: 'Psicologia', value: 'mdi-head-snowflake' },
    { text: 'Enfermeiro', value: 'mdi-doctor' },
    { text: 'Negócios', value: 'mdi-handshake' },
    { text: 'Cérebro', value: 'mdi-brain' },
    { text: 'Coração', value: 'mdi-heart-pulse' },
    { text: 'Saúde & Bem Estar', value: 'mdi-heart-plus' },
    { text: 'Cuidados com o Paciente', value: 'mdi-mother-heart' },
    { text: 'Diagnóstico', value: 'mdi-puzzle-heart' },
    { text: 'Ressonância', value: 'mdi-radioactive' },
    { text: 'Pediatria', value: 'mdi-car-child-seat' },
    { text: 'Transporte de Paciente', value: 'mdi-transit-transfer' },
    { text: 'Acessibilidade', value: 'mdi-wheelchair-accessibility' },
    { text: 'Medicação', value: 'mdi-medical-bag' },
    { text: 'Paciente Aguardando', value: 'mdi-account-clock' },
    { text: 'Ajuda', value: 'mdi-hand-heart' },
    { text: 'Higiene', value: 'mdi-lotion-plus' },
    { text: 'Conserto', value: 'mdi-hammer-wrench' },
    { text: 'Curativo', value: 'mdi-bandage' },
    { text: 'Edifício', value: 'mdi-office-building-marker' },
    { text: 'Manutenção', value: 'mdi-wrench' },
    { text: 'Dentista', value: 'mdi-tooth' },
    { text: 'Pulmão', value: 'mdi-lungs' },
    { text: 'Alimentação', value: 'mdi-silverware-fork-knife' },
    { text: 'Localização', value: 'mdi-map-marker-plus' },
    { text: 'Casa', value: 'mdi-home-plus' },
    { text: 'Idoso', value: 'mdi-human-cane' },
    { text: 'Localização Hospitalar', value: 'mdi-hospital-marker' },
    { text: 'Medicação Intravenosa', value: 'mdi-iv-bag' },
    { text: 'Perigo', value: 'mdi-skull-scan-outline' },
    { text: 'Vírus', value: 'mdi-virus-outline' },
    { text: 'Exame', value: 'mdi-diabetes' },
    { text: 'Genética', value: 'mdi-dna' },
    { text: 'Máscara', value: 'mdi-face-mask-outline' },
    { text: 'Microscópio', value: 'mdi-microscope' },
    { text: 'Pessoa', value: 'mdi-walk' },
    { text: 'Receita', value: 'mdi-file-document-edit' },
    { text: 'Documento', value: 'mdi-file-document-multiple-outline' },
    { text: 'Telefone', value: 'mdi-phone-dial' },
    { text: 'Cofre', value: 'mdi-piggy-bank-outline' },
  ];
  return data;
}
