import * as Authentication from './modules/authentication';
import * as Preloader from './modules/preloader';
import * as DepartmentStore from './modules/department';
import * as CategoryStore from './modules/category';
import * as ActivityStore from './modules/activity';
import * as JobStore from './modules/job';
import * as UserStore from './modules/user';
import * as BedStore from './modules/beds';
import * as RoleStore from './modules/role';
import * as AttendanceStore from './modules/attendance'
import * as PreferenceStore from './modules/preference'

export async function refreshAllData() {
  const promises = [
    DepartmentStore.actions.GetAll(true),
    CategoryStore.actions.GetAll(true),
    ActivityStore.actions.GetAll(true),
    JobStore.actions.GetAll(true),
    BedStore.actions.GetAll(true),
    RoleStore.actions.GetAll(true),
    AttendanceStore.actions.GetAll(true),
    PreferenceStore.actions.GetAll(true),
  ];

  await Promise.allSettled(promises);
}

export { Authentication, Preloader, DepartmentStore, CategoryStore, ActivityStore, JobStore, UserStore, BedStore, RoleStore, AttendanceStore, PreferenceStore };
