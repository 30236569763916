<template>
  <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="60.5" cy="60" r="60" fill="url(#paint0_linear_1259_808)" />
    <path
      opacity="0.4"
      d="M74.1406 47.4V72.5738C74.1406 81 68.8906 86.25 60.4906 86.25H53.6919C45.2919 86.25 40.0419 81 40.0419 72.6V47.4C40.0156 39 45.2656 33.75 53.6656 33.75H60.4906C68.8906 33.75 74.1406 39 74.1406 47.4Z"
      fill="white"
    />
    <path
      d="M83.1431 49.8145L91.9369 58.6083C92.6981 59.3695 92.6981 60.6295 91.9369 61.3908L83.1431 70.1845C82.3819 70.9458 81.1219 70.9458 80.3606 70.1845C79.5994 69.4233 79.5994 68.1633 80.3606 67.402L85.7944 61.9683H57.7331C56.6569 61.9683 55.7644 61.0758 55.7644 59.9995C55.7644 58.9233 56.6569 58.0308 57.7331 58.0308H85.7944L80.3606 52.597C79.9669 52.2033 79.7831 51.7045 79.7831 51.2058C79.7831 50.707 79.9669 50.182 80.3606 49.8145C81.1219 49.027 82.3556 49.027 83.1431 49.8145Z"
      fill="white"
    />
    <defs>
      <linearGradient id="paint0_linear_1259_808" x1="60.5" y1="0" x2="60.5" y2="120" gradientUnits="userSpaceOnUse">
        <stop :stop-color="'var(--primary-c500)'" />
        <stop offset="1" :stop-color="'var(--primary-c700)'" />
      </linearGradient>
    </defs>
  </svg>
</template>