<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4"
      d="M16 23.1333C17.2003 23.1333 18.1733 22.1603 18.1733 20.96C18.1733 19.7597 17.2003 18.7867 16 18.7867C14.7997 18.7867 13.8267 19.7597 13.8267 20.96C13.8267 22.1603 14.7997 23.1333 16 23.1333Z"
      :fill="'var(--primary-c600)'" />
    <path
      d="M22.2 12.5867H9.79996C4.33329 12.5867 2.66663 14.2533 2.66663 19.72V22.2C2.66663 27.6667 4.33329 29.3333 9.79996 29.3333H22.2C27.6666 29.3333 29.3333 27.6667 29.3333 22.2V19.72C29.3333 14.2533 27.6666 12.5867 22.2 12.5867ZM16 24.9867C13.7733 24.9867 11.9733 23.1733 11.9733 20.96C11.9733 18.7467 13.7733 16.9333 16 16.9333C18.2266 16.9333 20.0266 18.7467 20.0266 20.96C20.0266 23.1733 18.2266 24.9867 16 24.9867Z"
      :fill="'var(--primary-c600)'" />
    <path opacity="0.4"
      d="M9.49338 12.6V11.04C9.49338 7.13333 10.6 4.53333 16 4.53333C21.4 4.53333 22.5067 7.13333 22.5067 11.04V12.6C23.1867 12.6133 23.8 12.64 24.3734 12.72V11.04C24.3734 7.44 23.5067 2.66666 16 2.66666C8.49338 2.66666 7.62671 7.44 7.62671 11.04V12.7067C8.18671 12.64 8.81338 12.6 9.49338 12.6Z"
      :fill="'var(--primary-c600)'" />
  </svg>
</template>