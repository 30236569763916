import { refreshAllData } from '@/store';
import { http } from '../api';
import { CreatePreferenceRequest, Preference } from '../model/Preference';

const controller = 'preference';

export async function getPreferences(): Promise<Preference[]> {
  const { data } = await http.get<Preference[]>(`${controller}/`,);
  return data
}

export async function update(id: string, payload: CreatePreferenceRequest): Promise<Preference> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);
  const { data } = await http.patch<Preference>(`${controller}/${id}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  
  await refreshAllData();
  return data;
}

function validateAndAppendAll(formData: FormData, payload: CreatePreferenceRequest) {
  for (const [key, value] of Object.entries(payload)) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    }
  }
}
