import { Filters, OptionSelect, PaginateRequest, PaginateResponse } from '@/core/model';
import { Job } from '@/core/model/Job';
import { getPaginate } from '@/core/service/JobService';
import { reactive } from 'vue';

const state = reactive<{ jobs?: Job[]; listJobsSelect: OptionSelect[] }>({
  jobs: undefined,
  listJobsSelect: [],
});

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<Job> {
  if (!state.jobs) return { data: [], page: 0, recordsTotal: 0 };
  let data = state.jobs;
  if (f?.name) data = data.filter((d) => d.job.toLowerCase().includes(f.name ?? ''));

  const recordsTotal = data.length;

  const start = (page - 1) * limit;
  const end = start + limit;
  data = data.slice(start, end);

  return { data, page, recordsTotal };
}

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.jobs || forceRefresh) {
      const { data } = await getPaginate();
      state.jobs = data;
      state.jobs.sort((a, b) => a.job.toLowerCase().localeCompare(b.job.toLowerCase()));
    }

    state.listJobsSelect = state.jobs.map((d) => ({ text: d.job, value: d.id }));
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Job>> {
    await this.GetAll();
    return applyFilters(paginate, filters);
  },
};

const getters = {};

export { state, actions, getters };
