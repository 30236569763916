<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M13 21C7.75329 21 3.5 16.7467 3.5 11.5C3.5 6.25329 7.75329 2 13 2C18.2467 2 22.5 6.25329 22.5 11.5C22.5 16.7467 18.2467 21 13 21Z"
      fill="white"
    />
    <path
      d="M3.20141 22.0001C3.38141 22.0001 3.56141 21.9301 3.69141 21.8001L5.55141 19.9401C5.82141 19.6701 5.82141 19.2301 5.55141 18.9501C5.28141 18.6801 4.84141 18.6801 4.56141 18.9501L2.70141 20.8101C2.43141 21.0801 2.43141 21.5201 2.70141 21.8001C2.84141 21.9301 3.02141 22.0001 3.20141 22.0001Z"
      fill="white"
    />
  </svg>
</template>