export const iconEditSwalfire = (primaryColor) => `<svg width="100" height="100" viewBox="0 0 40 40" fill="white" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="19.5" fill="${primaryColor}" />
<path opacity="0.4" d="M23.48 11H15.52C12.07 11 10 13.06 10 16.52V24.47C10 27.94 12.07 30 15.52 30H23.47C26.93 30 28.99 27.94 28.99 24.48V16.52C29 13.06 26.93 11 23.48 11Z" fill="'#FFFFFF'" />
<path d="M29.02 10.9798C27.23 9.17979 25.48 9.13979 23.64 10.9798L22.51 12.0998C22.41 12.1998 22.38 12.3398 22.42 12.4698C23.12 14.9198 25.08 16.8798 27.53 17.5798C27.56 17.5898 27.61 17.5898 27.64 17.5898C27.74 17.5898 27.84 17.5498 27.91 17.4798L29.02 16.3598C29.93 15.4498 30.38 14.5798 30.38 13.6898C30.38 12.7898 29.93 11.8998 29.02 10.9798Z" fill="'#FFFFFF'" />
<path d="M25.8601 18.4198C25.5901 18.2898 25.3301 18.1598 25.0901 18.0098C24.8901 17.8898 24.6901 17.7598 24.5001 17.6198C24.3401 17.5198 24.1601 17.3698 23.9801 17.2198C23.9601 17.2098 23.9001 17.1598 23.8201 17.0798C23.5101 16.8298 23.1801 16.4898 22.8701 16.1198C22.8501 16.0998 22.7901 16.0398 22.7401 15.9498C22.6401 15.8398 22.4901 15.6498 22.3601 15.4398C22.2501 15.2998 22.1201 15.0998 22.0001 14.8898C21.8501 14.6398 21.7201 14.3898 21.6001 14.1298C21.4701 13.8498 21.3701 13.5898 21.2801 13.3398L15.9001 18.7198C15.5501 19.0698 15.2101 19.7298 15.1401 20.2198L14.7101 23.1998C14.6201 23.8298 14.7901 24.4198 15.1801 24.8098C15.5101 25.1398 15.9601 25.3098 16.4601 25.3098C16.5701 25.3098 16.6801 25.2998 16.7901 25.2898L19.7601 24.8698C20.2501 24.7998 20.9101 24.4698 21.2601 24.1098L26.6401 18.7298C26.3901 18.6498 26.1401 18.5398 25.8601 18.4198Z" fill="'#FFFFFF'" />
</svg>`

export const iconDeleteSwalfire = (primaryColor) => `<svg width="100" height="100" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="19.5" fill="${primaryColor}" />
<path d="M29.0699 13.23C27.4599 13.07 25.8499 12.95 24.2299 12.86V12.85L24.0099 11.55C23.8599 10.63 23.6399 9.25 21.2999 9.25H18.6799C16.3499 9.25 16.1299 10.57 15.9699 11.54L15.7599 12.82C14.8299 12.88 13.8999 12.94 12.9699 13.03L10.9299 13.23C10.5099 13.27 10.2099 13.64 10.2499 14.05C10.2899 14.46 10.6499 14.76 11.0699 14.72L13.1099 14.52C18.3499 14 23.6299 14.2 28.9299 14.73C28.9599 14.73 28.9799 14.73 29.0099 14.73C29.3899 14.73 29.7199 14.44 29.7599 14.05C29.7899 13.64 29.4899 13.27 29.0699 13.23Z" fill="'#FFFFFF'" />
<path opacity="0.3991" d="M27.23 16.14C26.99 15.89 26.66 15.75 26.32 15.75H13.68C13.34 15.75 13 15.89 12.77 16.14C12.54 16.39 12.41 16.73 12.43 17.08L13.05 27.34C13.16 28.86 13.3 30.76 16.79 30.76H23.21C26.7 30.76 26.84 28.87 26.95 27.34L27.57 17.09C27.59 16.73 27.46 16.39 27.23 16.14Z" fill="'#FFFFFF'" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.5801 25C17.5801 24.5858 17.9159 24.25 18.3301 24.25H21.6601C22.0743 24.25 22.4101 24.5858 22.4101 25C22.4101 25.4142 22.0743 25.75 21.6601 25.75H18.3301C17.9159 25.75 17.5801 25.4142 17.5801 25Z" fill="'#FFFFFF'" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.75 21C16.75 20.5858 17.0858 20.25 17.5 20.25H22.5C22.9142 20.25 23.25 20.5858 23.25 21C23.25 21.4142 22.9142 21.75 22.5 21.75H17.5C17.0858 21.75 16.75 21.4142 16.75 21Z" fill="'#FFFFFF'" />
</svg>`

export const iconCreateSwalfire = (primaryColor, secondaryColor) => `<svg width="93" height="94" viewBox="0 0 53 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="26.5" cy="27" r="26" fill="${primaryColor}" />
<g transform="translate(5, 5) scale(0.8)">
<path opacity="0.4" d="M35.7051 8.73462H17.1551C11.752 8.73462 7.37695 13.1315 7.37695 18.5127V39.0971C7.37695 44.4784 11.7738 48.8752 17.1551 48.8752H35.6832C41.0863 48.8752 45.4613 44.4784 45.4613 39.0971V18.5127C45.4832 13.1096 41.0863 8.73462 35.7051 8.73462Z" fill="${secondaryColor}"/>
<path d="M31.5699 5.125H21.2887C19.0137 5.125 17.1543 6.9625 17.1543 9.2375V11.2938C17.1543 13.5688 18.9918 15.4062 21.2668 15.4062H31.5699C33.8449 15.4062 35.6824 13.5688 35.6824 11.2938V9.2375C35.7043 6.9625 33.8449 5.125 31.5699 5.125Z" fill="${secondaryColor}"/>
<path d="M32.9922 29.0776H17.6797C16.7828 29.0776 16.0391 28.3339 16.0391 27.437C16.0391 26.5401 16.7828 25.7964 17.6797 25.7964H32.9922C33.8891 25.7964 34.6328 26.5401 34.6328 27.437C34.6328 28.3339 33.8891 29.0776 32.9922 29.0776Z" fill="${secondaryColor}"/>
<path d="M27.2609 37.8276H17.6797C16.7828 37.8276 16.0391 37.0839 16.0391 36.187C16.0391 35.2901 16.7828 34.5464 17.6797 34.5464H27.2609C28.1578 34.5464 28.9016 35.2901 28.9016 36.187C28.9016 37.0839 28.1578 37.8276 27.2609 37.8276Z" fill="${secondaryColor}"/>
</g>
</svg>`


export const iconErrorSwalfire = (primaryColor, secondaryColor) => `<svg width="100" height="100" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="19.5" fill="${primaryColor}" />
<g transform="translate(9, 9) scale(0.8)">
<path opacity="0.4" d="M24.5929 10.0101V17.99C24.5929 19.2967 23.8929 20.5101 22.7612 21.1751L15.8312 25.1767C14.6996 25.8301 13.2995 25.8301 12.1562 25.1767L5.22621 21.1751C4.09454 20.5218 3.39453 19.3084 3.39453 17.99V10.0101C3.39453 8.70341 4.09454 7.49003 5.22621 6.82503L12.1562 2.82337C13.2879 2.17004 14.6879 2.17004 15.8312 2.82337L22.7612 6.82503C23.8929 7.49003 24.5929 8.69175 24.5929 10.0101Z" fill="${secondaryColor}"/>
<path d="M14 16.0416C13.5217 16.0416 13.125 15.645 13.125 15.1666V9.04163C13.125 8.56329 13.5217 8.16663 14 8.16663C14.4783 8.16663 14.875 8.56329 14.875 9.04163V15.1666C14.875 15.645 14.4783 16.0416 14 16.0416Z" fill="${secondaryColor}"/>
<path d="M13.9987 20.125C13.847 20.125 13.6954 20.09 13.5554 20.0316C13.4037 19.9733 13.287 19.8917 13.1703 19.7867C13.0653 19.67 12.9837 19.5417 12.9137 19.4017C12.8554 19.2617 12.832 19.11 12.832 18.9583C12.832 18.655 12.9487 18.3516 13.1703 18.13C13.287 18.025 13.4037 17.9434 13.5554 17.885C13.987 17.6984 14.5004 17.8033 14.8271 18.13C14.9321 18.2466 15.0137 18.3633 15.072 18.515C15.1303 18.655 15.1654 18.8067 15.1654 18.9583C15.1654 19.11 15.1303 19.2617 15.072 19.4017C15.0137 19.5417 14.9321 19.67 14.8271 19.7867C14.6054 20.0084 14.3137 20.125 13.9987 20.125Z" fill="${secondaryColor}"/>
</g>
</svg>`