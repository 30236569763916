import { refreshAllData } from '@/store';
import { http } from '../api';
import { Filters, OptionSelect, PaginateResponse } from '../model';
import { CreateActivityRequest, Activity } from '../model/Activity';

const controller = 'activity';

export async function getByCategory(categoryId: string): Promise<Activity[]> {
  const { data } = await http.get<Activity[]>(`${controller}?category=${categoryId}&showDisabled=true`);
  return data;
}

export async function getPaginate(filters?: Filters): Promise<PaginateResponse<Activity>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<Activity[]>(`${controller}?showDisabled=true`, {
    params,
  });

  return { page: 1, recordsTotal: data.length, data };
}

export async function getBy(id: string): Promise<Activity> {
  const { data } = await http.get<Activity>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateActivityRequest): Promise<Activity> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.post<Activity>(`${controller}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  await refreshAllData();
  return data;
}

export async function update(id: string, payload: CreateActivityRequest): Promise<Activity> {
  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.patch<Activity>(`${controller}/${id}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  await refreshAllData();
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  await refreshAllData();
  return status === 204;
}

export async function enable(id: string): Promise<Activity> {
  const { data } = await http.post(`${controller}/enable/${id}`);
  await refreshAllData();
  return data;
}

export async function disable(id: string): Promise<Activity> {
  const { data } = await http.post(`${controller}/disable/${id}`);
  await refreshAllData();
  return data;
}

export async function getSelectIconList(): Promise<OptionSelect[]> {
  const data = [
    { text: 'Cama de Hospital', value: 'mdi-bed' },
    { text: 'Prontuário Médico', value: 'mdi-clipboard-pulse' },
    { text: 'Recursos Humanos', value: 'mdi-human-handsup' },
    { text: 'Finanças', value: 'mdi-currency-usd' },
    { text: 'Paciente', value: 'mdi-account-heart' },
    { text: 'Agendamento', value: 'mdi-calendar-clock' },
    { text: 'Laboratório', value: 'mdi-flask' },
    { text: 'Cirurgia', value: 'mdi-human-baby-changing-table' },
    { text: 'Ambulância', value: 'mdi-ambulance' },
    { text: 'Emergência', value: 'mdi-alert' },
    { text: 'Raio-X', value: 'mdi-radiology-box-outline' },
    { text: 'Seringa', value: 'mdi-needle' },
    { text: 'Médico', value: 'mdi-stethoscope' },
    { text: 'Kit Médico', value: 'mdi-hospital-box' },
    { text: 'Cérebro', value: 'mdi-brain' },
    { text: 'Psicologia', value: 'mdi-head-snowflake' },
    { text: 'Enfermeiro', value: 'mdi-doctor' },
    { text: 'Negócios', value: 'mdi-handshake' },
    { text: 'Cérebro', value: 'mdi-brain' },
    { text: 'Coração', value: 'mdi-heart-pulse' },
    { text: 'Saúde & Bem Estar', value: 'mdi-heart-plus' },
    { text: 'Cuidados com o Paciente', value: 'mdi-mother-heart' },
    { text: 'Diagnóstico', value: 'mdi-puzzle-heart' },
    { text: 'Ressonância', value: 'mdi-radioactive' },
    { text: 'Pediatria', value: 'mdi-car-child-seat' },
    { text: 'Transporte de Paciente', value: 'mdi-transit-transfer' },
    { text: 'Acessibilidade', value: 'mdi-wheelchair-accessibility' },
    { text: 'Pílula', value: 'mdi-pill' },
    { text: 'Medicação', value: 'mdi-medical-bag' },
    { text: 'Paciente Aguardando', value: 'mdi-account-clock' },
    { text: 'Higiene Pessoal', value: 'mdi-hand-wash' },
    { text: 'Ajuda', value: 'mdi-hand-heart' },
    { text: 'Higiene', value: 'mdi-lotion-plus' },
    { text: 'Conserto', value: 'mdi-hammer-wrench' },
    { text: 'Curativo', value: 'mdi-bandage' },
    { text: 'Manutenção', value: 'mdi-wrench' },
    { text: 'Dentista', value: 'mdi-tooth' },
    { text: 'Escova/Pasta de Dente', value: 'mdi-toothbrush-paste' },
    { text: 'Pulmão', value: 'mdi-lungs' },
    { text: 'Alimentação', value: 'mdi-silverware-fork-knife' },
    { text: 'Localização', value: 'mdi-map-marker-plus' },
    { text: 'Casa', value: 'mdi-home-plus' },
    { text: 'Sangue', value: 'mdi-blood-bag' },
    { text: 'Idoso', value: 'mdi-human-cane' },
    { text: 'Localização Hospitalar', value: 'mdi-hospital-marker' },
    { text: 'Medicação Intravenosa', value: 'mdi-iv-bag' },
    { text: 'Perigo', value: 'mdi-skull-scan-outline' },
    { text: 'Vírus', value: 'mdi-virus-outline' },
    { text: 'Exame', value: 'mdi-diabetes' },
    { text: 'Termômetro', value: 'mdi-thermometer-plus' },
    { text: 'Genética', value: 'mdi-dna' },
    { text: 'Máscara', value: 'mdi-face-mask-outline' },
    { text: 'Microscópio', value: 'mdi-microscope' },
    { text: 'Helicóptero', value: 'mdi-helicopter' },
    { text: 'Pessoa', value: 'mdi-walk' },
    { text: 'Receita', value: 'mdi-file-document-edit' },
    { text: 'Documento', value: 'mdi-file-document-multiple-outline' },
    { text: 'Telefone', value: 'mdi-phone-dial' },
    { text: 'Maça', value: 'mdi-food-apple' },
    { text: 'Frango', value: 'mdi-food-turkey' },
    { text: 'Água', value: 'mdi-cup-water' },
    { text: 'Celular', value: 'mdi-cellphone-play' },
    { text: 'Café', value: 'mdi-coffee' },
    { text: 'Balança', value: 'mdi-scale' },
  ];
  return data;
}

function validateAndAppendAll(formData: FormData, payload: CreateActivityRequest) {
  for (const [key, value] of Object.entries(payload)) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    }
  }
}
