<template>
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M18.9987 0.5H4.9987C2.42036 0.5 0.332031 2.57667 0.332031 5.13167V16.8683C0.332031 19.4233 2.42036 21.5 4.9987 21.5H18.9987C21.577 21.5 23.6654 19.4233 23.6654 16.8683V5.13167C23.6654 2.57667 21.577 0.5 18.9987 0.5Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M20.1654 7.2085H14.332C13.8537 7.2085 13.457 6.81183 13.457 6.3335C13.457 5.85516 13.8537 5.4585 14.332 5.4585H20.1654C20.6437 5.4585 21.0404 5.85516 21.0404 6.3335C21.0404 6.81183 20.6437 7.2085 20.1654 7.2085Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M20.1667 11.875H15.5C15.0217 11.875 14.625 11.4783 14.625 11C14.625 10.5217 15.0217 10.125 15.5 10.125H20.1667C20.645 10.125 21.0417 10.5217 21.0417 11C21.0417 11.4783 20.645 11.875 20.1667 11.875Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M20.1654 16.5415H17.832C17.3537 16.5415 16.957 16.1448 16.957 15.6665C16.957 15.1882 17.3537 14.7915 17.832 14.7915H20.1654C20.6437 14.7915 21.0404 15.1882 21.0404 15.6665C21.0404 16.1448 20.6437 16.5415 20.1654 16.5415Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M7.91375 10.7552C9.40216 10.7552 10.6088 9.54864 10.6088 8.06023C10.6088 6.57183 9.40216 5.36523 7.91375 5.36523C6.42534 5.36523 5.21875 6.57183 5.21875 8.06023C5.21875 9.54864 6.42534 10.7552 7.91375 10.7552Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M8.84783 12.2952C8.2295 12.2369 7.58783 12.2369 6.9695 12.2952C5.0095 12.4819 3.4345 14.0335 3.24783 15.9935C3.23617 16.1569 3.28283 16.3202 3.3995 16.4369C3.51617 16.5535 3.66783 16.6352 3.83117 16.6352H11.9978C12.1612 16.6352 12.3245 16.5652 12.4295 16.4485C12.5345 16.3319 12.5928 16.1686 12.5812 16.0052C12.3828 14.0335 10.8195 12.4819 8.84783 12.2952Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>