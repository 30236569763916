<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M13.9987 2.3335C10.942 2.3335 8.45703 4.8185 8.45703 7.87516C8.45703 10.8735 10.802 13.3002 13.8587 13.4052C13.952 13.3935 14.0454 13.3935 14.1154 13.4052C14.1387 13.4052 14.1504 13.4052 14.1737 13.4052C14.1854 13.4052 14.1854 13.4052 14.197 13.4052C17.1837 13.3002 19.5287 10.8735 19.5404 7.87516C19.5404 4.8185 17.0554 2.3335 13.9987 2.3335Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M19.9252 16.5084C16.6702 14.3384 11.3618 14.3384 8.08349 16.5084C6.60182 17.5 5.78516 18.8417 5.78516 20.2767C5.78516 21.7117 6.60182 23.0417 8.07182 24.0217C9.70516 25.1184 11.8518 25.6667 13.9985 25.6667C16.1452 25.6667 18.2918 25.1184 19.9252 24.0217C21.3952 23.03 22.2118 21.7 22.2118 20.2534C22.2002 18.8184 21.3952 17.4884 19.9252 16.5084Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>