<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="4" fill="url(#paint0_linear_1214_640)" />
    <path
      d="M25.5119 11.85L19.5719 8.42C18.6019 7.86 17.4019 7.86 16.4219 8.42L10.4919 11.85C9.52187 12.41 8.92188 13.45 8.92188 14.58V21.42C8.92188 22.54 9.52187 23.58 10.4919 24.15L16.4319 27.58C17.4019 28.14 18.6019 28.14 19.5819 27.58L25.5219 24.15C26.4919 23.59 27.0919 22.55 27.0919 21.42V14.58C27.0819 13.45 26.4819 12.42 25.5119 11.85ZM18.0019 13.34C19.2919 13.34 20.3319 14.38 20.3319 15.67C20.3319 16.96 19.2919 18 18.0019 18C16.7119 18 15.6719 16.96 15.6719 15.67C15.6719 14.39 16.7119 13.34 18.0019 13.34ZM20.6819 22.66H15.3219C14.5119 22.66 14.0419 21.76 14.4919 21.09C15.1719 20.08 16.4919 19.4 18.0019 19.4C19.5119 19.4 20.8319 20.08 21.5119 21.09C21.9619 21.75 21.4819 22.66 20.6819 22.66Z"
      fill="white"
    />
    <defs>
      <linearGradient id="paint0_linear_1214_640" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
        <stop :stop-color="'var(--secondary-c600)'" />
        <stop offset="1" :stop-color="'var(--secondary-c700)'" />
      </linearGradient>
    </defs>
  </svg>
</template>