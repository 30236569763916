<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="4" fill="url(#paint0_linear_1157_12396)"/>
        <path d="M22.19 8H13.81C10.17 8 8 10.17 8 13.81V22.18C8 25.83 10.17 28 13.81 28H22.18C25.82 28 27.99 25.83 27.99 22.19V13.81C28 10.17 25.83 8 22.19 8ZM22.78 15.7L17.11 21.37C16.97 21.51 16.78 21.59 16.58 21.59C16.38 21.59 16.19 21.51 16.05 21.37L13.22 18.54C12.93 18.25 12.93 17.77 13.22 17.48C13.51 17.19 13.99 17.19 14.28 17.48L16.58 19.78L21.72 14.64C22.01 14.35 22.49 14.35 22.78 14.64C23.07 14.93 23.07 15.4 22.78 15.7Z" fill="white"/>
        <defs>
        <linearGradient id="paint0_linear_1157_12396" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
          <stop :stop-color="'var(--secondary-c600)'" />
        <stop offset="1" :stop-color="'var(--secondary-c700)'" />
        </linearGradient>
        </defs>
    </svg>
  </template>