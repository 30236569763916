<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.694 5.87342C12.7839 5.87342 13.6674 4.98993 13.6674 3.90009C13.6674 2.81025 12.7839 1.92676 11.694 1.92676C10.6042 1.92676 9.7207 2.81025 9.7207 3.90009C9.7207 4.98993 10.6042 5.87342 11.694 5.87342Z"
      :fill="'#45505F'" fill-opacity="1" />
    <path
      d="M4.30732 5.87342C5.39716 5.87342 6.28065 4.98993 6.28065 3.90009C6.28065 2.81025 5.39716 1.92676 4.30732 1.92676C3.21748 1.92676 2.33398 2.81025 2.33398 3.90009C2.33398 4.98993 3.21748 5.87342 4.30732 5.87342Z"
      :fill="'#45505F'" fill-opacity="1" />
    <path
      d="M11.694 14.0734C12.7839 14.0734 13.6674 13.1899 13.6674 12.1C13.6674 11.0102 12.7839 10.1267 11.694 10.1267C10.6042 10.1267 9.7207 11.0102 9.7207 12.1C9.7207 13.1899 10.6042 14.0734 11.694 14.0734Z"
      :fill="'#45505F'" fill-opacity="1" />
    <path
      d="M4.30732 14.0734C5.39716 14.0734 6.28065 13.1899 6.28065 12.1C6.28065 11.0102 5.39716 10.1267 4.30732 10.1267C3.21748 10.1267 2.33398 11.0102 2.33398 12.1C2.33398 13.1899 3.21748 14.0734 4.30732 14.0734Z"
      :fill="'#45505F'" fill-opacity="1" />
  </svg>
</template>
  