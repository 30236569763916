<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M18.8871 2.3335H9.12208C4.87541 2.3335 2.34375 4.86516 2.34375 9.11183V18.8768C2.34375 23.1235 4.87541 25.6552 9.12208 25.6552H18.8871C23.1337 25.6552 25.6654 23.1235 25.6654 18.8768V9.11183C25.6654 4.86516 23.1337 2.3335 18.8871 2.3335Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M9.55631 19.5767H11.2946C12.5663 19.5767 13.558 18.5851 13.558 17.3134V15.575C13.558 14.3033 12.5663 13.3117 11.2946 13.3117H9.0663C9.15963 11.2 9.64964 10.885 11.0613 10.0567C11.4813 9.8117 11.6096 9.27507 11.3646 8.85507C11.2013 8.57507 10.9096 8.42334 10.6063 8.42334C10.4546 8.42334 10.303 8.45838 10.163 8.54005C8.07463 9.77672 7.29297 10.5817 7.29297 14.1751V17.29C7.29297 18.5617 8.30797 19.5767 9.55631 19.5767Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M16.7047 19.5767H18.4431C19.7147 19.5767 20.7064 18.5851 20.7064 17.3134V15.575C20.7064 14.3033 19.7147 13.3117 18.4431 13.3117H16.2147C16.3081 11.2 16.7981 10.885 18.2097 10.0567C18.6297 9.8117 18.7581 9.27507 18.5131 8.85507C18.3497 8.57507 18.0581 8.42334 17.7547 8.42334C17.6031 8.42334 17.4514 8.45838 17.3114 8.54005C15.2231 9.77672 14.4414 10.5817 14.4414 14.1751V17.29C14.4531 18.5617 15.4681 19.5767 16.7047 19.5767Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>