import { Filters, OptionSelect, PaginateRequest, PaginateResponse } from '@/core/model';
import { Category } from '@/core/model/Category';
import { getPaginate, getByDepartment } from '@/core/service/CategoryService';
import { reactive } from 'vue';

const state = reactive<{ categories?: Category[]; listCategoriesSelect: OptionSelect[] }>({
  categories: undefined,
  listCategoriesSelect: [],
});

function applyFilters({ limit, page }: PaginateRequest, f?: Filters): PaginateResponse<Category> {
  if (!state.categories) return { data: [], page: 0, recordsTotal: 0 };
  let data = state.categories;
  if (f?.name) data = data.filter((d) => d.name.toLowerCase().includes(f.name ?? ''));
  if (f?.department) data = data.filter((d) => d.department.id === f?.department);
  const recordsTotal = data.length;

  const start = (page - 1) * limit;
  const end = start + limit;
  data = data.slice(start, end);
  return { data, page, recordsTotal };
}

const actions = {
  async GetAll(forceRefresh = false) {
    if (!state.categories || forceRefresh) {
      const { data } = await getPaginate();
      state.categories = data;
    }

    state.listCategoriesSelect = state.categories
      .filter((d) => d.enabled)
      .map((d) => ({ text: d.name, value: d.id }));
  },
  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Category>> {
    await this.GetAll();
    return applyFilters(paginate, filters);
  },
  async GetByDepartment(departmentId: string) {
    const data = await getByDepartment(departmentId);
    state.categories = data;
    state.listCategoriesSelect = data.map((d) => ({ text: d.name, value: d.id }));
  }
};

const getters = {
  async FilterByDepartment(departmentId: string) {
    if (state.categories) {
      const categories = state.categories.filter((category) => category.department.id === departmentId);
      state.listCategoriesSelect = categories.map((d) => ({ text: d.name, value: d.id }));
    }
  }
};

export { state, actions, getters };
