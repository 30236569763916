import { refreshAllData } from '@/store';
import { http } from '../api';
import { Filters, PaginateResponse } from '../model';
import { CreateUserRequest, User } from '../model/User';

const controller = 'user';

export async function getPaginate(filters?: Filters): Promise<PaginateResponse<User>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<User[]>(`${controller}/`);

  return { page: 1, recordsTotal: data.length, data };
}

export async function getPaginateAgents(filters?: Filters): Promise<PaginateResponse<User>> {
  const params: Record<string, string> = {};
  if (filters?.name) {
    params['name'] = filters?.name;
  }
  const { data } = await http.get<User[]>(`/${controller}?agents=true`);

  return { page: 1, recordsTotal: data.length, data };
}

export async function getBy(id: string): Promise<User> {
  const { data } = await http.get<User>(`${controller}/${id}`);
  return data;
}

export async function create(payload: CreateUserRequest): Promise<User> {
  payload.roles = [payload.permission!];
  payload.jobs = [payload.job!];

  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.post<User>(`${controller}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  await refreshAllData();
  return data;
}

export async function update(id: string, payload: CreateUserRequest): Promise<User> {
  payload.roles = [payload.permission!];
  payload.jobs = [payload.job!];

  const formData = new FormData();
  validateAndAppendAll(formData, payload);

  const { data } = await http.patch<User>(`${controller}/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
  return data;
}

export async function deleted(id: string): Promise<boolean> {
  const { status } = await http.delete(`${controller}/${id}`);
  await refreshAllData();
  return status === 204;
}

function validateAndAppendAll(formData: FormData, payload: CreateUserRequest) {
  for (const [key, value] of Object.entries(payload)) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    }
  }
}
