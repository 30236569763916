<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19.5" :fill="'var(--primary-c100)'" stroke="#D6DBE1" />
    <path
      d="M29.0699 13.23C27.4599 13.07 25.8499 12.95 24.2299 12.86V12.85L24.0099 11.55C23.8599 10.63 23.6399 9.25 21.2999 9.25H18.6799C16.3499 9.25 16.1299 10.57 15.9699 11.54L15.7599 12.82C14.8299 12.88 13.8999 12.94 12.9699 13.03L10.9299 13.23C10.5099 13.27 10.2099 13.64 10.2499 14.05C10.2899 14.46 10.6499 14.76 11.0699 14.72L13.1099 14.52C18.3499 14 23.6299 14.2 28.9299 14.73C28.9599 14.73 28.9799 14.73 29.0099 14.73C29.3899 14.73 29.7199 14.44 29.7599 14.05C29.7899 13.64 29.4899 13.27 29.0699 13.23Z"
      :fill="'var(--secondary-c700)'" />
    <path opacity="0.3991"
      d="M27.23 16.14C26.99 15.89 26.66 15.75 26.32 15.75H13.68C13.34 15.75 13 15.89 12.77 16.14C12.54 16.39 12.41 16.73 12.43 17.08L13.05 27.34C13.16 28.86 13.3 30.76 16.79 30.76H23.21C26.7 30.76 26.84 28.87 26.95 27.34L27.57 17.09C27.59 16.73 27.46 16.39 27.23 16.14Z"
      :fill="'var(--secondary-c700)'" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M17.5801 25C17.5801 24.5858 17.9159 24.25 18.3301 24.25H21.6601C22.0743 24.25 22.4101 24.5858 22.4101 25C22.4101 25.4142 22.0743 25.75 21.6601 25.75H18.3301C17.9159 25.75 17.5801 25.4142 17.5801 25Z"
      :fill="'var(--secondary-c700)'" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M16.75 21C16.75 20.5858 17.0858 20.25 17.5 20.25H22.5C22.9142 20.25 23.25 20.5858 23.25 21C23.25 21.4142 22.9142 21.75 22.5 21.75H17.5C17.0858 21.75 16.75 21.4142 16.75 21Z"
      :fill="'var(--secondary-c700)'" />
  </svg>
</template>