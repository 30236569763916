<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="4" fill="url(#paint0_linear_1214_680)" />
    <path
      d="M25.5119 11.85L19.5719 8.42C18.6019 7.86 17.4019 7.86 16.4219 8.42L10.4919 11.85C9.52187 12.41 8.92188 13.45 8.92188 14.58V21.42C8.92188 22.54 9.52187 23.58 10.4919 24.15L16.4319 27.58C17.4019 28.14 18.6019 28.14 19.5819 27.58L25.5219 24.15C26.4919 23.59 27.0919 22.55 27.0919 21.42V14.58C27.0819 13.45 26.4819 12.42 25.5119 11.85ZM17.2519 13.75C17.2519 13.34 17.5919 13 18.0019 13C18.4119 13 18.7519 13.34 18.7519 13.75V19C18.7519 19.41 18.4119 19.75 18.0019 19.75C17.5919 19.75 17.2519 19.41 17.2519 19V13.75ZM18.9219 22.63C18.8719 22.75 18.8019 22.86 18.7119 22.96C18.5219 23.15 18.2719 23.25 18.0019 23.25C17.8719 23.25 17.7419 23.22 17.6219 23.17C17.4919 23.12 17.3919 23.05 17.2919 22.96C17.2019 22.86 17.1319 22.75 17.0719 22.63C17.0219 22.51 17.0019 22.38 17.0019 22.25C17.0019 21.99 17.1019 21.73 17.2919 21.54C17.3919 21.45 17.4919 21.38 17.6219 21.33C17.9919 21.17 18.4319 21.26 18.7119 21.54C18.8019 21.64 18.8719 21.74 18.9219 21.87C18.9719 21.99 19.0019 22.12 19.0019 22.25C19.0019 22.38 18.9719 22.51 18.9219 22.63Z"
      fill="white"
    />
    <defs>
      <linearGradient id="paint0_linear_1214_680" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
        <stop :stop-color="'var(--secondary-c600)'" />
        <stop offset="1" :stop-color="'var(--secondary-c700)'" />
      </linearGradient>
    </defs>
  </svg>
</template>