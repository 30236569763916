<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.44536 2.33331H6.2287C3.6737 2.33331 2.33203 3.67498 2.33203 6.21831V8.43498C2.33203 10.9783 3.6737 12.32 6.21703 12.32H8.4337C10.977 12.32 12.3187 10.9783 12.3187 8.43498V6.21831C12.3304 3.67498 10.9887 2.33331 8.44536 2.33331Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      opacity="0.4"
      d="M21.7814 2.33331H19.5647C17.0214 2.33331 15.6797 3.67498 15.6797 6.21831V8.43498C15.6797 10.9783 17.0214 12.32 19.5647 12.32H21.7814C24.3247 12.32 25.6664 10.9783 25.6664 8.43498V6.21831C25.6664 3.67498 24.3247 2.33331 21.7814 2.33331Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      d="M21.7814 15.6683H19.5647C17.0214 15.6683 15.6797 17.01 15.6797 19.5533V21.77C15.6797 24.3133 17.0214 25.655 19.5647 25.655H21.7814C24.3247 25.655 25.6664 24.3133 25.6664 21.77V19.5533C25.6664 17.01 24.3247 15.6683 21.7814 15.6683Z"
      :fill="'var(--primary-c600)'"
    />
    <path
      opacity="0.4"
      d="M8.44536 15.6683H6.2287C3.6737 15.6683 2.33203 17.01 2.33203 19.5533V21.77C2.33203 24.325 3.6737 25.6667 6.21703 25.6667H8.4337C10.977 25.6667 12.3187 24.325 12.3187 21.7817V19.565C12.3304 17.01 10.9887 15.6683 8.44536 15.6683Z"
      :fill="'var(--primary-c600)'"
    />
  </svg>
</template>

