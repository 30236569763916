import { AxiosResponse } from 'axios';
import { http } from '../api';

import { Credential } from '../model/Credential';
import { LoginResponse } from '../model/LoginResponse';
import { getSocket } from '../websocket';

export async function login({ username, password }: Credential): Promise<LoginResponse> {
  const { data } = await http.post<LoginResponse, AxiosResponse>(`login`, {
    username,
    password,
    isMobile: false,
    remember: false,
  });

  const socket = getSocket();
  if (socket) socket.emit('login', data.jwtTokenWebSocket);

  return data;
}

// export async function logout(id: string): Promise<LoginResponse> {
//   const { data } = await http.post<LoginResponse, AxiosResponse>(`logout`, {
//     id,
//     isMobile: false,
//     forceDelete: true,
//     forceLogout: true,
//   });
//   return data;
// }
