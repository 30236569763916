<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3794 6.11997L4.42936 20.07C3.99936 20.5 3.27936 20.46 2.92936 19.97C2.42936 19.28 2.32937 18.32 2.84937 17.49L5.60937 13.06C5.97937 12.47 5.97937 11.51 5.60937 10.92L2.84937 6.48997C1.91937 5.00997 2.98936 3.08997 4.72936 3.08997H16.1794C16.8594 3.08997 17.6994 3.55997 18.0594 4.12997L18.5294 4.86997C18.7594 5.27997 18.7094 5.78997 18.3794 6.11997Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M22.1299 13.11L16.9499 20.01C16.5899 20.5 15.7899 20.9 15.1699 20.9H8.00986C7.11986 20.9 6.66987 19.82 7.29987 19.19L18.8099 7.67999C19.2599 7.22999 20.0299 7.30999 20.3699 7.85999L22.2199 10.83C22.6299 11.47 22.5899 12.5 22.1299 13.11Z"
      fill="white"
    />
  </svg>
</template>
