<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="4" fill="url(#paint0_linear_1214_666)" />
    <path
      d="M20.8906 9.45H15.1106C14.7106 9.45 14.3906 9.13 14.3906 8.73C14.3906 8.33 14.7106 8 15.1106 8H20.8906C21.2906 8 21.6106 8.32 21.6106 8.72C21.6106 9.12 21.2906 9.45 20.8906 9.45Z"
      fill="white"
    />
    <path
      d="M19.9981 25.97V23.03C19.9981 21.22 21.2181 20 23.0281 20H25.9681C26.1981 20 26.4181 20.02 26.6281 20.06C26.6481 19.82 26.6681 19.58 26.6681 19.33C26.6681 14.54 22.7781 10.65 17.9981 10.65C13.2181 10.65 9.32812 14.54 9.32812 19.33C9.32812 24.11 13.2181 28 17.9981 28C18.8481 28 19.6581 27.86 20.4381 27.64C20.1581 27.17 19.9981 26.61 19.9981 25.97ZM18.7481 19C18.7481 19.41 18.4081 19.75 17.9981 19.75C17.5881 19.75 17.2481 19.41 17.2481 19V14C17.2481 13.59 17.5881 13.25 17.9981 13.25C18.4081 13.25 18.7481 13.59 18.7481 14V19Z"
      fill="white"
    />
    <path
      d="M25.97 21H23.04C21.76 21 21 21.76 21 23.03V25.97C21 27.24 21.76 28 23.04 28H25.97C27.24 28 28 27.24 28 25.97V23.03C28 21.76 27.24 21 25.97 21ZM23.92 26.06C23.92 26.38 23.66 26.64 23.33 26.64C23.01 26.64 22.75 26.38 22.75 26.06V22.94C22.75 22.62 23.01 22.36 23.33 22.36C23.66 22.36 23.92 22.62 23.92 22.94V26.06ZM26.25 26.06C26.25 26.38 25.99 26.64 25.67 26.64C25.35 26.64 25.08 26.38 25.08 26.06V22.94C25.08 22.62 25.35 22.36 25.67 22.36C25.99 22.36 26.25 22.62 26.25 22.94V26.06Z"
      fill="white"
    />
    <defs>
      <linearGradient id="paint0_linear_1214_666" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
        <stop :stop-color="'var(--secondary-c600)'" />
        <stop offset="1" :stop-color="'var(--secondary-c700)'" />
      </linearGradient>
    </defs>
  </svg>
</template>