import { http } from "../api";
import { Attendance } from "../model/Attendance";
import { Filters, OptionSelect, PaginateResponse } from '../model';

const controller = "/attendance";

export async function getPaginate(filters?: Filters): Promise<PaginateResponse<Attendance>> {
    const params: Record<string, string> = {};
    if (filters?.name) {
      params['name'] = filters?.name;
    }
    const { data } = await http.get<Attendance[]>(`${controller}/open`, {
      params,
    });

    return { page: 1, recordsTotal: data.length, data };
}

export async function getAllAttendances() : Promise<Attendance[]> {
    const response = await http.get(controller);
    return response.data;
}

export async function getAttendanceByName(attendanceId: string) : Promise<Attendance[]> {
    const response = await http.get<Attendance[]>(`${controller}/${attendanceId}`);
    return response.data;
}